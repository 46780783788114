.br-3 {
	border-radius: 3px;
}
.br-4 {
	border-radius: 4px;
}
.br-8 {
	border-radius: 8px;
}
.br-p50 {
	border-radius: 50%;
}

.btlr-3 {
	border-top-left-radius: 3px;
}

.bblr-3 {
	border-bottom-left-radius: 3px;
}

.btrr-3 {
	border-top-right-radius: 3px;
}

.bbrr-3 {
	border-bottom-right-radius: 3px;
}

.b-divider {
	border-bottom: 1px solid rgb(230, 230, 230);
}
