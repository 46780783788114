/** All Round Margin **/
.margin-0 {
	margin: 0;
}
.margin-auto {
	margin: 0 auto;
}
.margin-8 {
	margin: 8px;
}
.margin-16 {
	margin: 16px;
}

/** Bottom Margins **/
.margin-b0 {
	margin-bottom: 0;
}
.margin-b8 {
	margin-bottom: 0.5rem;
}
.margin-b16 {
	margin-bottom: 1rem;
}
.margin-b32 {
	margin-bottom: 2rem;
}

/** Right Margins **/
.margin-r4 {
	margin-right: 0.25rem;
}
.margin-r8 {
	margin-right: 0.5rem;
}
.margin-r16 {
	margin-right: 1rem;
}

/** Left Margins **/
.margin-l8 {
	margin-left: 0.5rem;
}

/** Top Margins **/
.margin-t8 {
	margin-top: 0.5rem;
}
.margin-t16 {
	margin-top: 1rem;
}
.margin-t32 {
	margin-top: 2rem;
}
.margin-t48 {
	margin-top: 3rem;
}
.margin-t64 {
	margin-top: 4rem;
}

/** Top-Bottom Margin **/
.margin-tb0 {
	margin-top: 0;
	margin-bottom: 0;
}
.margin-tb8 {
	margin: 0.5rem 0;
}
.margin-tb16 {
	margin: 1rem 0;
}
.margin-tb32 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.margin-tb48 {
	margin: 3rem 0;
}
.margin-tb64 {
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.margin-tb80 {
	margin: 5rem 0;
}


/** Left-Right Margin **/
.margin-lr4 {
	margin: 0 0.25rem;
}
.margin-lr8 {
	margin: 0 0.5rem;
}
