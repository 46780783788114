.btn {
	cursor: pointer;
	padding: 4px;
}

/** Button Sizes **/
.btn-s {
	height: 36px;
}
.btn-m {
	height: 44px;
	max-width: 240px;
}
.btn-l {
	height: 56px;
	max-width: 280px;
  min-width: 280px;
  font-weight: 600;
}

/** Button Types **/
.btn-cta {
	color: #000;
	max-width: 200px;
}
.btn-regular.login, .btn-universal {
	max-width: 320px;
}
.btn-cta-main, .btn-cta-onboarding, .btn-regular, .btn-universal, .btn-gray {
	color: #000;
	width: 100%;

	&:disabled {
		background-color: $lightGray;
		cursor: not-allowed;
	}
}
.btn-regular {
	background-color: $secondary;
	&:not(:disabled):hover, &:not(:disabled):active {
		background-color: $secondaryHover;
	}
}
.btn-universal {
	background-color: $universal;
	&:not(:disabled):hover, &:not(:disabled):active {
		color: $secondary;
		background-color: $mediumGray;
	}
}
.btn-gray {
	background-color: $mediumGray;
	&:not(:disabled):hover, &:not(:disabled):active {
		background-color: $mediumGrayHover;
	}
}
.btn-cta, .btn-cta-main, .btn-cta-onboarding {
	background-color: $accent;
	text-decoration: none;

	&:not(:disabled):hover, &:not(:disabled):active {
		background-color: $accentHover;
	}
}
.btn-cta-main.signup {
	max-width: 320px;
	color: #000;
	font-weight: 500;
	&:not(:disabled):hover, &:not(:disabled):active {
		background-color: $accentHover;
	}
}
.btn-link {
	background-color: transparent;
	color: $darkGray;
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		color: $primary;
	}
}

@include mobile {
	.btn-l {
		font-size: 1.15rem;
		//padding: 0.75rem;
	}
	.btn-m {
		font-size: 1rem;
		padding: 0.6rem;
	}
}

/** Tablet **/
@include tablet {
	.btn-l {
		font-size: 1.2rem;
		padding: 0.625rem;
	}
	.btn-m {
		font-size: 1rem;
		padding: 0.45rem;
	}
}

/** Desktop **/
@include desktop {
	.btn-l {
		font-size: 1.25rem;
		padding: 0.75rem;
	}
	.btn-m {
		font-size: 1rem;
		padding: 0.6rem;
	}
}


/** Media buttons **/
.media-button-root {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: unset;
	width: 100%;
	max-width: unset;
	padding: 36px 0px;
	align-items: center;
	max-width: 120px;


	@media screen and (min-width: 480px)  {
		max-width: 120px;
	}
}

.media-button {
	display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
	border-radius: 4px;
	text-align: center;
	padding: 2px;
	margin-left: 4px;

	&--white {
		@extend .media-button;
		background: white;
		border: 1.5px solid #49ACF4;
		width: 80px;

		&:hover, &:active {
			background: #49ACF4;
			.media-button-text {
				font-weight: 500;
				color: white;
			}
		}
	}

	&--blue {
		@extend .media-button;
		background: #EAF7FE;
	}

	&--gray {
		@extend .media-button;
		background: #F2F2F2;
	}

	&:hover, &:acive {
		.media-button-icon {
	  	background: #49ACF4;
	  	color: white;
		}
	}

	@media screen and (min-width: 480px)  {
		border-radius: 4px;
		padding: 2px;
	}
}

.media-button-icon {
  width: 24px;
  color: #49ACF4;

  &:hover {
  	background: #49ACF4;
  	color: white;
  	border-radius: 50%;
  }
}

.media-button-text {
	font-size: 14px;
	margin-top: -4px;
	//display: none;

  @media screen and (min-width: 480px) {
  	display: block;
  }
}

.media-icon-text {
	font-size: 14px;
}

.button {
	cursor: pointer;
	border-radius: 4px;
	padding: 8px;
	width: 100%;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	font-weight: 600;
	height: 44px;
	max-width: 200px;

	&.cta {
		background-color: $accent;
		color: #000;
		&:hover {
			background-color: $accentHover;
		}
	}
	
	&.regular {
		color: white;
		background-color: $secondary;
		&:hover, &:active {
			background-color: $secondaryHover;
		}
	}

	&.tertiary {
		background-color: #d8d8d8;
		color:  $secondary;

		&:hover, &:active {
			background-color: $primary;
			color: #FFF;
		}
	}

	:disabled {
		background-color: lightgray;
		cursor: not-allowed;
	}

	&.draft {
		background-color: $mediumGrayHover;
		margin-right: 1rem;

		&:hover {
			background-color: $darkGray;
			color: #FFF;
		}
	}

	&.display-mobile-none {
		display: none;
	}

	@media screen and (min-width: 480px) {
		min-width: 110px;
		display: flex;
		margin-top: unset;
		height: 44px;
		max-width: 200px;

		&.display-mobile-none {
			display: flex;
		}
	}
}
