.flex {
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
}
.flex-center {
  @extend .flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
  &-center {
    @extend .flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-horizontal-center {
    @extend .flex;
    flex-direction: column;
    align-items: center;
  }
  &-center-no-align {
    @extend .flex;
    flex-direction: column;
    justify-content: center;
  }
  &-spacebetween {
    @extend .flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.flex-row {
  @extend .flex;
  flex-direction: row;
  &-center {
    @extend .flex;
    flex-direction: row;
    justify-content: center;
  }
  &-spacearound {
    @extend .flex;
    justify-content: space-around;
  }
  &-spacebetween {
    @extend .flex;
    justify-content: space-between;
  }
  &-align-center {
    @extend .flex;
    flex-direction: row;
    align-items: center;
  }
}
.j-flex-end {
  justify-content: flex-end;
}
.a-flex-end {
  align-items: flex-end;
}
.error {
  color: red;
  font-size: 1rem;
  @media screen and (max-width: 660px) {
		font-size: .8rem;
	}
}


/** Others **/
.cursor-p {
  cursor: pointer;
}
