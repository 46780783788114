/** All Round Padding **/
.padding-8 {
	padding: 0.5rem;
}
.padding-16 {
	padding: 1rem;
}
.padding-32 {
	padding: 2rem;
}
.padding-64 {
	padding: 4rem;
}
.padding-80 {
	padding: 5rem;
}


/** Bottom Padding **/
.padding-b16 {
	padding-bottom: 1rem;
}
.padding-b32 {
	padding-bottom: 2rem;
}


/** Top Padding **/
.padding-t4 {
	padding-top: 0.25rem;
}
.padding-t16 {
	padding-top: 1rem;
}
.padding-t80 {
	padding-top: 5rem;
}
.padding-t96 {
	padding-top: 6rem;
}


/** Right Padding **/
.padding-r8 {
	padding-right: .5rem;
}
.padding-r32 {
	padding-right: 2rem;
}
.padding-r64 {
	padding-right: 4rem;
}


/** Top-Bottom Paddinig **/
.padding-tb8 {
	padding-top: .5rem;
	padding-bottom: .5rem;
}
.padding-tb16 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.padding-tb32 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.padding-tb48 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.padding-tb64 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.padding-tb80 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.padding-tb96 {
	padding-top: 6rem;
	padding-bottom: 6rem;
}


/** Left-Right Padding **/
.padding-lr8 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.padding-lr16 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.padding-lr32 {
	padding: 0 2rem;
}
.padding-lr64 {
	padding: 0 4rem;
}
