/** Headers **/
h1, h2 {
	margin: 12px 0;
}

h3 {
	margin: .5rem 0;
}

h1 {
	font-size: 2.7rem;
}

h3 {
	font-weight: 300;
}

a.color-secondary {
	color: $secondary;
	&:hover {
		color: $primary;
		text-decoration: underline;
	}
}

.modal-header {
	font-size: 1.25rem;
	font-weight: 600;
	color: white;
	&.success {
		color: #05CD75;
	}
	&.primary {
		color: $primary;
	}
	&.accent {
		color: $accent;
	}
	&.error {
		color: #FFA3A3;
	}
	@media screen and (max-width: 480px)  {
		text-align: center;
    font-size: 1.1rem;
  }
}

.modal-content {
	padding: 16px 20px;
	@media screen and (max-width: 480px)  {
		padding: 16px;
  }
}

.modal-section-header {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #354052;
	color: white;
	width: 100%;
	margin-left: -24px;
	margin-right: -24px;
	margin-top: -24px;
	padding: 1rem 0;
}

.page-header, .form-header, .section-header {
	font-size: 1.5rem;
	font-weight: 600;

	@media screen and (max-width: 660px)  {
    font-size: 1.35rem;
  }

	@media screen and (max-width: 480px)  {
		text-align: center;
    font-size: 1.25rem;
  }
}

.section-header {
  @media screen and (min-width: 480px)  {
		text-align: left;
  }
}

.section-header, .form-header, .page-header, .modal-header {
  font-family: 'Raleway', 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
}

.section-subheader, .form-subheader {
	font-family: Nunito, 'Helvetica', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1.35rem;
  font-weight: 700;
  margin: 1rem 0;

  @media screen and (max-width: 480px)  {
    font-size: 1.125rem;
  }
}

.form-header {
	text-align: center;
}

.form-subheader {
	font-size: 1.25rem;
	@media screen and (max-width: 480px)  {
    font-size: 1rem;
  }
}

.center-text-mobile {
	text-align: center;
	@media screen and (min-width: 660px)  {
		text-align: unset;
	}
}

@include mobile {
	h2 {
		font-size: 1.5rem;
		margin-top: 0.25rem;
	}
	h3 {
		font-size: 1.2rem;
	}
	.paragraph-s {
		font-size: 1rem;
		text-align: center;
	}
	.paragraph-xs {
		text-align: center;
		font-size: .85rem;
	}
}


@include tablet {
	h2 {
		font-size: 1.75rem;
	}
	h3 {
		font-size: 1.375rem;
	}
	.paragraph-s {
		font-size: 1.125rem;
	}
	.paragraph-xs {
		font-size: .875rem;
	}
}


@include desktop {
	h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.5rem;
	}
	.paragraph-s {
		font-size: 1.25rem;
	}
	.paragraph-xs {
		font-size: .9rem;
	}
}

/** Backgrounds **/
.bg-circles {
  background-image: url("../assets/imgs/bg_circles.png");
  background-repeat: repeat;
  background-size: contain;
}

.bg-houses {
  background-image: url("../assets/imgs/bg_houses.png");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}

.bg-map-loading {
  background-image: url("../assets/imgs/loading_landing.png");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}


/** Links **/
.apartment-link {
	cursor: pointer;
}

.link {
	color: #49ACF4;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

/** Display **/
@media screen and (max-width: 479px) {
	.display-mobile-none {
		display: none;
	}
	.display-tablet-only {
		display: none;
	}
}

.display-mobile-only {
	display: none;
	@media screen and (max-width: 660px) {
		display: block;
	}
}

.display-desktop-only {
	display: flex;
	@media screen and (max-width: 660px) {
		display: none;
	}
}

.display-block {
	display: block;
}

.hide {
	display: none;
}

.hide-visually {
	color: transparent;
	position: absolute;
}

/** Input **/
.ha-input {
  box-sizing: border-box;
  border: 1px solid hsl(0,0%,80%);
	width: 100%;
  height: 38px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color: #616161;
	font-weight: 300;
	font-size: .875rem;
	background: #FFF;
  text-overflow: ellipses;

	&:hover {
		border: 1px solid $accent;
	}
	&:focus {
		border: 1.5px solid $accent;
	}

	&.agent-profile {
		border: 2px solid hsl(0,0%,80%);

		&:hover {
			border: 1px solid #8CD018;
		}
		&:focus {
			border: 2px solid #8CD018;
		}

	}
}

/** Positions **/
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.relative {
	position: relative;
}
.ta-center {
	text-align: center;
}
.ta-left {
	text-align: left;
}
.ta-justify {
	text-align: justify;
}

/** Text **/
.ellipsize {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


/** Brand Specific **/
.ha-search-bar {
	height: 40px;
	border-radius: 4px;
	border: none;
}

.apartment-location {
  font-size: .85rem;
  color: $mediumDarkGray;
  margin-top: -8px;

  @media screen and (min-width: 480px) {
    margin-top: unset;
  }
}

.landingpage-searchbox-container {
	position: absolute;
	top: calc(50% - 30px);
	display: flex;
	width: 100%;
	justify-content: center;
}

.location-text {
  font-size: 14px;
  padding-top: 2px;
  font-weight: 300;
}

/** Social Media Icons **/
.social-icon {
	height: 28px;
	width: 28px;

	@media screen and (min-width: 660px) {
		height: 36px;
		width: 36px;
	}
}

.fb-icon {
  background-image: url("../assets/social/icon-fb.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.fb-icon:hover {
  background-image: url("../assets/social/icon-fb-hover.svg");
}

.ig-icon {
  background-image: url("../assets/social/icon-insta.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.ig-icon:hover {
  background-image: url("../assets/social/icon-insta-hover.svg");
}

.tw-icon {
  background-image: url("../assets/social/icon-tw.svg");
  background-repeat: no-repeat;
  background-size: cover;
	background-position: 70% 50%;
}
.tw-icon:hover {
  background-image: url("../assets/social/icon-tw-hover.svg");
}

