/** Brand Colors **/
$primary: #8CD018;
$primaryLight: #d4f4ff; /*#EAF5D7;*/
$primaryHover: #49ACF4; /*#00AEF8;*/
$secondary: #923B96;//354052
$secondaryLight: rgb(203, 153, 206);
$secondaryDark: #491D4B;
$secondaryHover: #77307A;
$accent: #F7BF23; /*#2CB44D #F4983E #8CD018 #FABA29*/
$accentLight: #FCEBBD;
$accentHover: #d7a108;
$secondaryWhite: #EAEBED;
$text: #354052;
$universal: #354052;


/** State Colors **/
$success: #05CD75;
$error: #ffa3a3;
$disabled: #f4f4f7;


/** Universal Colors **/
$white: #ffffff;
$gray: #E6E6E6;
$lightGray: #F2F2F2;
$lightMediumGray: #D8D8D8;
$mediumGray: #979797;
$mediumGrayHover: #ABABAB;
$mediumDarkGray: #828282;
$darkGray: #616161;
$blackGray: #272822;
$buttonGray: #F7F7F7;


// /** Other Colors **/
$lightBlue: #BBE5FD;


/** Background Colors **/
$tagBackground: #5A6577;
$background: $lightGray;
$editorBackground: $blackGray;


/** Background-Color Classes **/
.bg-accent {
	background-color: $accent;
}

.bg-primary {
	background-color: $primary;
}

.bg-secondary {
	background-color: $secondary;
}

.bg-universal {
	background-color: $universal;
}

// .bg-secondary-gradient {
// 	background: linear-gradient(to right, $secondary, $secondaryLight)
// }

.bg-gray {
	background-color: $gray;
}

.bg-bluegray {
	background-color: rgba(208, 211, 220, 0.30);
}

.bg-lightgray {
	background-color: $lightGray;
}

.bg-lightmediumgray {
	background-color: $lightMediumGray;;
}

.bg-lightblue {
	background-color: $lightBlue;
}

.bg-white {
	background-color: #FFF;
}


/** Color Classes **/
.color-accent {
	color: $accent;
}
.color-accent-dark {
	color: $accentHover;
}
.color-primary {
	color: $primary;
}
.color-secondary {
	color: $secondary;
}
.color-gray {
	color: $gray;
}
.color-gray-l {
	color: $lightGray;
}
.color-gray-m {
	color: $mediumGray;
}
.color-gray-md {
	color: $mediumDarkGray;
}
.color-gray-d {
	color: $darkGray;
}
.color-gray-b {
	color: $blackGray;
}
.color-white {
	color: #FFF;
}
.color-lightblue {
	color: $lightBlue;
}
.color-text {
  color: $text;
}
.color-lightgray {
  color: #E6E6E6;
}
