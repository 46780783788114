.section-subcontainer {
	display: flex;
	margin: 0 auto;
	max-width: 1280px;
	padding: 0 32px;
}
.form-container {
	color: rgb(53, 64, 82);
	max-width: 320px;
}
.section-root {
	margin-top: unset;

	@media screen and (min-width: 660px)  {
		flex-direction: row;
		margin-top: 32px;
		margin-bottom: 32px;
	}
}
.content-container {
	display: flex;
	//padding: 0 8px;
	flex-direction: column;

	@media screen and (min-width: 660px)  {
		flex-direction: row;
	}

	&-sb {
		@extend .content-container;
		@media screen and (min-width: 660px)  {
			justify-content: space-between;
		}
	}
}

.empty-layout {
	background-color: #FFF;
	border: 1px dashed lightgray;
	border-radius: 8px;
	justify-content: center;
	flex-direction: column;
	display: flex;
	align-items: center;
	height: 400px;
	padding: 0 1rem;
}

.empty-layout-subheader {
	font-size: 1rem;
	margin-top: -8px;
}

.info-nugget {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	width: 100%;
	background: #FFF;
	justify-content: center;
	align-items: center;
	height: 4rem;
	margin-bottom: 1rem;
	border-radius: 4px;
	border: .25px solid $gray;

	> span {
		font-size: 1.2rem;
	}

	> p {
		font-size: 1.025rem;
	}

	&:hover {
		> span {
			color: #49ACF4;
		}
	}

	@media screen and (min-width: 480px)  {
		min-width: 120px;
		margin-right: .5rem;
		> span {
			font-size: 1.75rem;
		}
	}
}


/** Mobile **/
@include mobile {
	.sb-desktop-center-mobile {
		justify-content: center;
	}
	.container-child {
		text-align: center;
		width: 100%;
		padding-left: .5rem;
		padding-right: .5rem;
		&-left {
			text-align: center;
			width: 100%;
			order: 2;
			padding-left: .5rem;
			padding-right: .5rem;
		}
		&-right {
			text-align: center;
			width: 100%;
			order: 1;
			padding-left: .5rem;
			padding-right: .5rem;
		}
	}
	.landing-banner {
		display: flex;
		height: 560px;
		flex-direction: column;
	}
	.nav-header {
		background-color: #fff;
		padding: 0 .5rem;
		position: fixed;
		top: 0;
		z-index: 10;
	}
	.section-container {
		text-align: center;
	}
	.section-subcontainer {
		flex-direction: column;
		justify-content: center;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.form-container {
		max-width: 280px;
	}
}


/** Tablet **/
@include tablet {
	.sb-desktop-center-mobile {
		justify-content: space-between;
	}
	.container-child {
		width: 50%;
		padding: 0 1rem;
		&-left {
			width: 50%;
			padding: 0 .5rem;
		}
		&-right {
			width: 50%;
			padding: 0 .5rem;
		}
	}
	.landing-banner {
		display: flex;
		flex-direction: row;
		height: 560px;
	}
	.nav-header {
		position: fixed;
		background-color: #fff;
		padding: 0 .5rem;
		position: fixed;
		top: 0;
		z-index: 10;
		padding: 1rem;
	}
	.section-container {
		padding: 0 1rem;
	}
	.section-subcontainer {
		flex-direction: row;
		justify-content: center;
		padding: 1rem;
	}
}


/** Desktop **/
@include desktop {
	.sb-desktop-center-mobile {
		justify-content: space-between;
	}
	.container-child {
		width: 50%;
		padding: 0 1rem;
		&-left {
			width: 50%;
			padding: 0 1rem;
		}
		&-right {
			width: 50%;
			padding: 0 1rem;
		}
	}
	.landing-banner {
		display: flex;
		flex-direction: row;
		height: 660px;
	}
	.section-container {
		padding: 0 2rem;
	}
	.section-subcontainer {
		flex-direction: row;
		justify-content: center;
		padding: 2rem 0;
	}
}


/** Positioning **/
.align-center {
	align-items: center;
}

.space-between {
	justify-content: space-between;
}


/** Displays **/
.display-block {
	display: block;
}

.table {
	display: flex;

	> div:first-child {
		flex: 100%;
	}

	@media screen and (min-width: 480px) {
		> div:first-child {
			flex: 4;
		}
	}

	> div:nth-child(2) {
		flex: 2;
	}
	> .not-for-mobile {
		@media screen and (max-width: 760px) {
			visibility: hidden;
			width: 0;
		}
	}
	> div:nth-child(3) {
		flex: 2;
	}
}

/** Others **/
.card {
	&:hover {
		border: 1px solid $accent;
	}
}

.calendar-container {
	display: flex;
	align-items: center;
	padding: 1.5rem;
	background-color: white;
	border-radius: 8px;
	margin: 0 auto;
	flex-direction: column;

	> .calendar-root {
		margin-bottom: 1rem;
	}
	@media screen and (max-width: 480px) {
		&.list-item {
			padding: .5rem;
		}
		padding: 1rem;
	}
	@media screen and (min-width: 640px) {
		align-items: center;
		min-width: 500px;
		flex-direction: row;

		> .calendar-root {
			margin-right: 1rem;
			margin-bottom: unset;
		}
  }
}

.calendar-root {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background: white;
	position: relative;
	border-top: 2px solid red;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-left: 1px ridge;
	border-right: 1px ridge;
	box-shadow: 2px 2px;
	height: 120px;
	width: 120px;
	margin-right: 1rem;
	padding: .5rem 1rem;

	&.list-item {
		@media screen and (max-width: 480px) {
			height: 90px;
			width: 90px;
			> .month {
				text-align: center;
				font-size: 1rem;
				margin-top: 8px;
			}
	
			> .day {
				font-size: 40px;
				text-align: center;
			}
		}
	}

	> .month {
		display: flex;
		justify-content: center;
		font-size: 1rem;
		margin-top: 8px;
	}

	> .day {
		text-align: center;
		font-size: 60px;
	}

	@media screen and (min-width: 720px) {
		> .month {
			text-align: center;
			font-size: 18px;
			margin-top: 8px;
		}

		> .day {
			font-size: 72px;
			text-align: center;
		}
  }
}

.option-card {
 	cursor: pointer;
	min-height: 120px;
	min-width: 120px;
	max-width: 300px;
	margin: 12px;
	text-align: center;
	border-radius: 3px;

	&:hover, &:focus {
		transform: scale(1.04);
		box-shadow: 5px 3px 5px 1px rgba(0,0,0,.25);
	}

	&.selected {
		border: 2px solid green;

		&:hover {
			box-shadow: unset;
		}
	}

	&.not-selected {
		opacity: 0.4;
		cursor: default;

		&:hover {
			box-shadow: unset;
		}
	}

	&.option-type {
		background-color: antiquewhite;

		&.selected {
			border: 2px solid white;

			&:hover {
				box-shadow: unset;
			}
		}
	}

	&.option-location {
		background-color: lavender;

		&.selected {
			border: 2px solid #49acf4;

			&:hover {
				box-shadow: unset;
			}
		}
	}

	&.option-now {
		> p{
			color: white;
		}
		background-color: firebrick;
	}
	&.option-soon {
		background-color: coral;
	}
	&.option-notsoon {
		background-color: orange;
	}
	&.option-farout {
		background-color: lightseagreen;
	}
}