.react-tagsinput {
  background-color: #f7f7f7; /*#fff;*/
  border: 1px solid #D8D8D8;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  height: 136px;
  border-radius: 12px;

  @media screen and (max-width: 480px)  {
    height: 80px;
  }
}

.react-tagsinput:hover {
  border-color: #49ACF4;
}

.react-tagsinput > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 2px;
}

.react-tagsinput--focused {
  border-color: #49ACF4;
}

.react-tagsinput-tag {
  background-color: #5A6577;
  border-radius: 2px;
  border: 1px solid #5A6577;
  color: #FFF;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 6px;
  border-radius: 12px;
  height: 28px;
}

.react-tagsinput-remove {
  cursor: pointer;
  padding-left: 8px;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100%;
}

.react-autosuggest__suggestion:hover {
  color: #FFF;
  background: #5A6577;
}

.react-autosuggest__suggestion > span {
  color: #777;
  cursor: pointer;
  display: block;
  font-size: 13px;
  padding: 6px;
  border-radius: 4px;
}

.react-autosuggest__container {
  width: 100%;
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

/*.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}*/