.all__btn_container {
	align-items: center;
  border-radius: 4px;
  //box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.5);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  height: 44px;
  position: relative;
  max-width: 320px;
}
.all__btn_text {
	color: #FFF;
	font-size: 1rem;
	margin: 0;
	padding-left: 0.5rem;
}
.facebook__btn_image {
	height: 32px;
	padding-left: 4px;
	width: 36px;
}
.google__btn_image {
	height: 42px;
	width: 42px;
}

@media (max-width: 425px) {
	.facebook__btn_image {
		height: 24px;
		padding-left: 3px;
		width: 27px;
	}
	.google__btn_image {
		height: 36px;
		width: 36px;
	}
	.all__btn_text {
		font-size: 16px;
		padding-left: 1rem;
	}
}
