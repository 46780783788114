@import '../../styles/mixins';
@import '../../styles/colors';
@import '../../styles/device-sizes';

.navbar-link {
  text-decoration: none;
  color: #354052;
  margin-right: 2em;
	line-height: 2;
	font-weight: 600;
	padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid $accent;
  }
}

.notif-button {
  margin-left: 12px;
}

.hamburger-menu {
  @include mobile {
	  position: relative;
	  top: -10px;
	  left: -14px;
	  flex: 0 0 auto;
	  order: 1;
	  width: 30px;
	  height: 49px;
	  cursor: pointer;
	  margin-left: 12px;
	  color: #354052;

	  &:before {
	    content: '';
	    display: inline-block;
	    position: absolute;
	    top: 36px;
	    left: 13px;
	    width: 30px;
	    height: 1.2px;
	    background-color: #354052;
	    box-shadow: 0 -7px 0 0, 0 7px 0 0;
	  }

	  &.selected:before {
	    box-shadow: none;
	    @include transform(rotate(45deg));
	    @include transition(transform 0.25s)
	  }

	  &.selected:after {
	    content: '';
	    display: inline-block;
	    position: absolute;
	    top: 36px;
	    left: 13px;
	    width: 30px;
	    height: 1.2px;
	    background-color: #354052;
	  	color: #354052;
	    @include transform(rotate(-45deg));
	    @include transition(transform 0.25s)
	  }

	  &:after {
	    content: '';
	    display: block;
	    width: 30px;
	    height: 22px;
	    background-color: transparent;
	    position: absolute;
	    left: 25px;
	    top: 20px;
	  }
  }
}
