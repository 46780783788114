/** Heights **/
.height-24 {
	height: 24px;
}
.height-32 {
	height: 32px;
}
.height-40 {
	height: 40px;
}
.height-44 {
	height: 44px;
}
.height-50 {
	height: 50px;
}
.height-60 {
	height: 60px;
}
.height-70 {
	height: 70px;
}
.height-80 {
	height: 80px;
}
.height-300 {
	height: 300px;
}
.height-360 {
	height: 360px;
}
.height-400 {
	height: 400px;
}
.height-600 {
	height: 600px;
}
.height-p100 {
	height: 100%;
}
.vh-80 {
	height: 80vh;
}
.vh-100 {
	height: 100vh;
}
.mvh-100 {
	min-height: 100vh;
}

/** Max-Width **/
.mw-280 {
	max-width: 280px;
}
.mw-320 {
	max-width: 320px;
}
.mw-360 {
	max-width: 360px;
}
.mw-400 {
	max-width: 25rem;
}
.mw-640 {
	max-width: 40rem;
}
.mw-1280 {
	max-width: 80rem;
}
.mw-1680 {
	max-width: 105rem;
}


/** Min-Width **/
.miw-280 {
	min-width: 280px;
}


/** Width **/
.width-p50 {
	width: 50%;
}
.width-p80 {
	width: 80%;
}
.width-p90 {
	width: 90%;
}
.width-p100 {
	width: 100%;
}
.width-240 {
	width: 240px;
}
.width-300 {
	width: 300px;
}
.width-640 {
	width: 640px;
}


/** Font-Sizes **/
.font-s8 {
	font-size: 0.5rem;
}
.font-s12 {
	font-size: 12px;
}
.font-s14 {
	font-size: 14px;
}
.font-s16 {
	font-size: 1rem;
}
.font-s20 {
	font-size: 1.25rem;
}
.font-s24 {
	font-size: 1.5rem;
}
.font-s32 {
	font-size: 2rem;
}
.font-s40 {
	font-size: 2.5rem;
}
.font-s64 {
	font-size: 4rem;
}


/** Font-Weights **/
.fw-300 {
	font-weight: 300;
}
.fw-400 {
	font-weight: 400;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}
