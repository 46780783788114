@import '../../styles/colors';

.background-circles {
	height: 600px;
}

.dashboard-header {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
}

.homepage-root {
	margin-left: auto;
	margin-right: auto;
	height: 100%;
}

.featured-root {
	> div:nth-child(1) {
		width: 100%;
	}
}

.homepage-main {
	padding: 1rem;
	width: calc(100% - 64px);

	@media screen and (min-width: 480px) {
		min-height: calc(100vh - 60px);
	}
}

.homepage-data-overview {
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 480px) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.landingpage-searchbox-container {
	position: absolute;
	top: calc(50% - 30px);
	display: flex;
	width: 100%;
	justify-content: center;
}

.homepage-data-chart {
	justify-content: space-between;
	align-items: center;
}

.left-nav {
	padding: 2rem .5rem;
	display: flex;
	width: auto;
	height: auto;
	min-height: calc(100vh - 60px);
	position: relative;

	@media screen and (max-width: 480px) {
		width: 60px;
	}
}

.draft-icon-root {
	border: 1px solid gray;
	cursor: pointer;
	border-style: dashed;
	border-radius: 50%;
	height: 240px;
	width: 240px;
	margin: 0 auto;
	background-color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		border-color: $accent;
		.draft-icon {
			fill: $accent !important;
		}
	}
	@media screen and (max-width: 480px) {
		height: 160px;
		width: 160px;	
	}
}

.draft-icon {
	fill: gray !important;
}

/** Buttons **/
.icon-button-root {
	width: 100%;

	p { 
		color: white;
		font-size: .875rem;
	}

	&:hover, &:focus {
		outline: none;
		
		.icon-button {
			fill: $accent !important;
		}
		p { color: $accent}
	}
}

.icon-button-root:not(:last-child) {
	margin-bottom: 40px;
	@media screen and (min-width: 660px) {
		margin-bottom: 52px;
	}

}

.icon-button-root:first-child {
	margin-bottom: 40px;
	@media screen and (min-width: 660px) {
		margin-bottom: 44px;
	}
}

.icon-button {
	cursor: pointer;
	font-size: 24px !important;

	&.icon-pay {
		font-size: 30px !important;
	}

	@media screen and (min-width: 660px) {
		font-size: 32px !important;

		&.icon-pay {
			font-size: 36px !important;
		}
	}
}


/** Charts **/
.analytics-one-root {
	width: 100%;
	@media screen and (min-width: 660px) {
		width: 45%;
	}
}

.analytics-one, .analytics-two {
	background-color: white;
	padding: 12px 8px;
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;

	@media screen and (min-width: 660px) {
		margin-top: .5rem;
		padding-right: 1rem;
		width: calc(100% - 8px);
	}
}

.analytics-one {
	@media screen and (min-width: 660px) {
		margin-top: .5rem;
		padding-right: 1rem;
		width: calc(100% - 8px);
	}
}

.analytics-two-root {
	width: 100%;
	// @media screen and (min-width: 660px) {
	// 	margin-top: unset;
	// 	width: 45%;
	// }
}

.analytics-two {
	max-height: 320px;
	border-radius: 8px;
	> svg {
		width: unset;
	}
	@media screen and (min-width: 660px) {
		padding: unset;
		margin-top: .5rem;
	}
}

.listing-facilities-check {
	display: flex;
	padding: 0 1rem;
	align-items: center;
	flex-direction: row;
}

.no-listing {
	width: 100%;
	max-width: 400px;
}

/** Listings **/
.listings-root {
	@media screen and (min-width: 320px) and (max-width: 769px)  {
		display: grid;
		grid-row-gap: 1rem;
		grid-column-gap: 1rem;
		grid-template-columns: 1fr;
		margin-top: 1rem;
  }

  @media screen and (min-width: 770px) {
		display: grid;
		grid-row-gap: 1rem;
		grid-column-gap: 1rem;
		margin-top: 2rem;
  	grid-template-columns: 1fr 1fr;
  }
}
