@mixin transform($args) {
  -webkit-transform: $args;
  -ms-transform: $args;
  -moz-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin transition($args) {
  -moz-transition: $args;
  -webkit-transition: $args;
  transition: $args;
}